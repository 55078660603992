<template>
  <div class="search-page">
    <Header title="Your results" :description="`${foundedItems} RENTALS`" image="/images/apartment_rf.jpg"/>
    <div class="search-content">
      <b-container v-if="accommodationList.length">
        <b-row class="justify-content-between mb-5">
          <b-col cols="12" md="auto" >
            <RFSelect label="Sort By" :full-width="false" background="#DADBD7" :list="[{ label: 'Price Low to High', value: 'asc', function: true }, { label: 'Price High to Low', value: 'desc', function: true }]" v-model="sorting" @function="sortList(sorting)" />
          </b-col>
          <b-col cols="auto" class="d-flex align-items-center">
            <div class="toggle-map" @click="toggleMap = !toggleMap">
              <transition name="fade">
                <span class="show" v-if="!toggleMap">{{$t('Show Map')}}</span>
                <span class="hide" v-else>{{$t('Hide Map')}}</span>
              </transition>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" :md="!toggleMap ? '12' : '6'">
            <AccommodationPaginatedList :list="accommodationList" :opened-map="toggleMap" @mapCardIndex="showInfoWindow"/>
          </b-col>
          <transition name="fade">
            <b-col cols="12" md="6" v-show="toggleMap" class="map-container">
              <div class="close-map my-4 d-md-none">
                <FontAwesomeIcon class="cursor-pointer" :icon="['fas', 'times']" color="#fff" size="lg" @click="toggleMap = !toggleMap"/>
              </div>
              <GmapMap :center="centerPosition" :zoom="12" class="search-map" ref="map">
                <GmapMarker v-for="(accommodation, index) in accommodationList" :icon="accommodation.resourceType.toLowerCase() === accommodations.ROOM ? roomMarker : apartmentMarker" :key="index" :position="accommodationPosition(accommodation)"
                            @click="showInfoWindowAndScroll(index)">
                  <GmapInfoWindow v-if="toggleInfo && toggleInfoIndex === index" :options="{ pixelOffset: { width: 0, height: 0 } }" @closeclick="toggleInfo = false">
                    <div class="mr-4">
                      {{`${accommodation.name} - ${accommodation.addressStreet}, ${accommodation.addressZipCode}`}}
                    </div>
                  </GmapInfoWindow>
                </GmapMarker>
              </GmapMap>
            </b-col>
          </transition>
        </b-row>
      </b-container>
      <b-container v-else-if="!accommodationList.length && loading">
        <b-row>
          <b-col>
            <div class="loading">
              <RFLoader class="mr-3" :size="25" color="#24425B" border-color="#fff"/> {{$t('Loading...')}}
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="empty-list" v-if="!accommodationList.length && !loading">
      <b-container>
        <b-row>
          <b-col>
            <span class="montserrat-bold rf-blue-color">{{$t('There are no results.')}}</span>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import RFSelect from '@/components/forms/RFSelect'
import RFLoader from '@/components/forms/RFLoader'
import AccommodationPaginatedList from '@/components/accomodations/AccommodationPaginatedList'

import { accommodations } from '@/enums'
import { searchService } from '@/services'
import { store } from '@/stores'

export default {
  name: 'SearchPage',
  components: {
    Header,
    RFSelect,
    RFLoader,
    AccommodationPaginatedList,
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.searchStore.searchParams.startDate) {
      next({ name: 'homepage' })
    } else {
      next()
    }
  },
  data() {
    return {
      accommodations,

      accommodationList: [],
      toggleMap: false,
      toggleInfo: false,
      toggleInfoIndex: null,
      sorting: null,
      loading: null,
      roomMarker: null,
      apartmentMarker: null
    }
  },
  computed: {
    centerPosition() {
      const coordinates = {lat: 45.4640976, lng: 9.1897378};
      if(this.accommodationList.length > 0){
        let accomodationListFiltered = this.accommodationList.filter(x => x.addressLatitude !== null && x.addressLongitude !== null)[0];
        coordinates.lat = accomodationListFiltered.addressLatitude;
        coordinates.lng = accomodationListFiltered.addressLongitude;
      }
      return coordinates;
    },
    foundedItems() {
      return this.accommodationList.length
    }
  },
  async beforeMount() {
    this.loading = true
    const searchParams = this.$store.state.searchStore.searchParams
    const { startDate, endDate, categoryId, price } = searchParams

    this.accommodationList = await this.getAccommodationList(startDate, endDate, categoryId, price)

    if (this.accommodationList && this.accommodationList.length) {
      this.$nextTick(() => {
        this.$refs.map.$mapPromise.then(map => {
          this.roomMarker = {
            url: '/images/arrow_orange_hover.png',
            scaledSize: new google.maps.Size(30, 20),
          }

          this.apartmentMarker = {
            url: '/images/green_roof.png',
            scaledSize: new google.maps.Size(30, 20),
          }
        })
      })

      this.sortList('asc')
    }

    this.loading = false
  },
  methods: {
    async getAccommodationList(startDate, endDate, categoryId, price) {
      return await searchService.search(startDate, endDate, categoryId, price)
    },
    accommodationPosition(accommodation) {
      return {
        lat: accommodation.addressLatitude,
        lng: accommodation.addressLongitude,
      }
    },
    showInfoWindow(index) {
      this.toggleInfo = true
      this.toggleInfoIndex = index
    },
    showInfoWindowAndScroll(index) {
      this.showInfoWindow(index)
      let element = document.getElementById(`${index}`)
      let position = element.getBoundingClientRect();
      window.scrollTo({top: position.top + window.scrollY - 110, behavior: 'smooth'});
    },
    sortList(sorting) {
      switch (sorting) {
        case 'asc':
          this.sortByPrice('asc')
          break

        case 'desc':
          this.sortByPrice('desc')
          break
      }
    },
    sortByPrice(sorting) {
      if (sorting === 'asc') {
        this.accommodationList.sort((firstItem, secondItem) => parseFloat(firstItem.monthlyAmount) - parseFloat(secondItem.monthlyAmount))
      } else {
        this.accommodationList.sort((firstItem, secondItem) => parseFloat(secondItem.monthlyAmount) - parseFloat(firstItem.monthlyAmount))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search-page {
  .search-content {
    background-color: $gray;
    margin-top: 50px;
    padding: 30px 0;

    .sort-title {
      @include font-style($montserrat-bold, 'bold', $font-14);
      color: $dark-blue;
      display: inline-block;
    }

    .toggle-map {
      @include font-style($montserrat-bold, 'bold', $font-14);
      color: $dark-blue;
      text-transform: uppercase;
      cursor: pointer;
    }

    .map-container {
      position: relative;
      top: 125px;
      left: 0;
      transform: translate(0, 0);
      background-color: $black;
      height: 100vh;
      z-index: 100;
      width: 100%;

      .close-map {
        text-align: right;
      }

      .search-map {
        height: 400px;
      }
    }

    .loading {
      @include font-style($montserrat, 'semibold', $font-16);
      display: flex;
      align-items: center;
      color: $dark-blue;
    }

    .empty-list {
      @include font-style($montserrat, 'semibold', $font-16);
      background-color: $gray;
      padding: 20px 0 60px 0;
    }
  }
}
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {
  .search-page {
    .search-content {
      .map-container {
        position: sticky;
        top: 110px;
        left: 0;
        transform: translate(0, 0);
        background-color: transparent;
        z-index: 25;
        height: 110px;

        .apartments-map {
          width: 100%;
          height: 400px;
        }
      }
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'sm')) and (max-width: map-get($grid-breakpoints, 'md')) {
  .search-page {
    .search-content {
      .map-container {
        transform: translate(0, 0);
        position: relative;
        left: 0;
        top: 20px;
        z-index: 0;
      }
    }
  }
}
</style>
